(function () {
  'use strict';

  function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
  }

  function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
  }

  function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter);
  }

  function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
  }

  function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;

    for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

    return arr2;
  }

  function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  function env() {
    var bodyClasses = _toConsumableArray(document.body.classList);

    return bodyClasses.includes("dev") ? "dev" : "prod";
  }

  var mainMinified = /main-([a-z0-9]+)\.min\./.source;
  var dotMainMinified = /[^/]+-([a-z0-9]+)\.main\.min\./.source;
  var unminified = /((?:[^/]+\.)?main)\./.source;
  var base = "/(?:".concat(mainMinified, "|").concat(dotMainMinified, "|").concat(unminified, ")");
  var cssRe = new RegExp("".concat(base, "css$"));

  function css() {
    var elts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _toConsumableArray(document.getElementsByTagName("link"));
    var link = elts.find(function (elt) {
      var rel = elt.rel,
          href = elt.href;
      return rel === "stylesheet" && cssRe.test(href);
    });
    return link === null || link === void 0 ? void 0 : link.href;
  }

  var jsRe = new RegExp("".concat(base, "js$"));

  function js() {
    var elts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _toConsumableArray(document.getElementsByTagName("script"));
    var script = elts.find(function (elt) {
      return jsRe.test(elt.src);
    });
    return script === null || script === void 0 ? void 0 : script.src;
  }

  var idRe = new RegExp(base);

  function id(href) {
    var matches = href === null || href === void 0 ? void 0 : href.match(idRe);
    return matches === null || matches === void 0 ? void 0 : matches.slice(1).find(function (group) {
      return typeof group !== "undefined";
    });
  }

  function injectInfo(targetEnv) {
    if (typeof targetEnv === "undefined" || env() === targetEnv) {
      var _document = document,
          body = _document.body;
      var hrefCss = css();
      var hrefJs = js();
      var idCss = id(hrefCss);
      var idJs = id(hrefJs);
      var pCss = document.createElement("p");
      var pJs = document.createElement("p");
      pCss.innerHTML = "CSS: ".concat(idCss);
      pJs.innerHTML = "JS: ".concat(idJs);
      body.appendChild(pCss);
      body.appendChild(pJs);
    }
  }

  function init() {
    injectInfo();
  }

  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", init);
  } else {
    init();
  }

}());
